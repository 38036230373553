import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { arc as ArcFn } from '@visx/shape';
// eslint-disable-next-line react/function-component-definition
function AnimatedPieArc({ path, arc, arcThicknessDelta, animate, getColor, onClick = () => { }, onHover = () => { }, disabled = false, }) {
    const [{ shouldAnimate }, api] = useSpring(() => ({
        shouldAnimate: 0,
        config: {
            duration: 200,
        },
    }), [animate]);
    useEffect(() => {
        if (animate) {
            api.start({
                from: { shouldAnimate: 0 },
                to: { shouldAnimate: 1 },
            });
        }
        else {
            api.start({
                from: { shouldAnimate: 1 },
                to: { shouldAnimate: 0 },
            });
        }
    }, [animate, api]);
    const onClickHandler = () => {
        if (!disabled) {
            onClick(arc);
        }
    };
    const onHoverHandler = (isHovered) => {
        if (!disabled) {
            onHover(arc, isHovered);
        }
    };
    const innerRadius = path.innerRadius()(arc);
    const outerRadius = path.outerRadius()(arc);
    return (_jsx(animated.path, { d: shouldAnimate.to((value) => ArcFn(Object.assign(Object.assign({}, arc), { innerRadius: innerRadius - (arcThicknessDelta * value), outerRadius }))(arc) || ''), fill: getColor(arc), onClick: onClickHandler, onTouchStart: onClickHandler, onMouseOver: () => onHoverHandler(true), onMouseOut: () => onHoverHandler(false), style: Object.assign({}, !disabled && ({
            cursor: 'pointer',
        })) }));
}
export default AnimatedPieArc;
