import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { BodySemiBold } from '@components/styled/Typography';
const Button = styled.button((props) => (Object.assign(Object.assign({ position: 'relative', flex: 1, width: 'auto', height: 'auto', alignItems: 'center', justifyContent: 'center', background: 'none', border: 'none', font: 'inherit', outline: 'inherit', padding: `${props.theme.spacing_sizes.s}px ${props.theme.spacing_sizes.m}px`, 
    // TODO: move colors
    color: props.disabled ? '#B0B5BA' : props.theme.palette.text.secondary, borderRadius: props.theme.shape.borderRadius, cursor: props.disabled ? 'default' : 'pointer', userSelect: 'none' }, props.selected && ({
    cursor: 'default',
    color: props.disabled ?
        props.theme.palette.text.secondary :
        props.theme.palette.text.primary,
    backgroundColor: props.theme.customColors.surface.surface,
    // TODO: check all shadows and move them into theme config
    boxShadow: '0px 10px 15px -3px rgba(0, 0, 0, 0.08)',
})), props.displayDelimiter && !props.selected && ({
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '1px',
        height: '16px',
        top: '25%',
        left: '-1px',
        backgroundColor: '#D7DBDF',
    },
}))));
const SegmentButton = ({ segment, selected, disabled, onClick, displayDelimiter = false, }) => {
    const handleClick = () => {
        if (!selected && !disabled) {
            onClick(segment.key);
        }
    };
    return (_jsx(Button, { selected: selected, disabled: disabled, displayDelimiter: displayDelimiter, onClick: handleClick, children: typeof segment.displayValue === 'string' && (_jsx(BodySemiBold, { children: segment.displayValue })) }));
};
export default SegmentButton;
